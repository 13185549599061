import React, { useState } from 'react';
import './App.css';
import ScratchCard from './components/scratchcard/index';

const settings = {
  finishPercent: 100,
  onComplete: () => console.log('The card is now clear!')
};

function App() {

  const [uploadFile, setUploadFile] = useState(false);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);

  const [dataFromChild, setDataFromChild] = useState('');

  const handleDataFromChild = (data) => {
    setDataFromChild(data);
    console.log(data);
  };

  const upLoad = (e) =>{
    const reader = new FileReader(); 
    reader.readAsDataURL(e.target.files.item(0));
    reader.onloadend=(ev)=>{
      const base64Url = ev.target.result;
      console.log(base64Url)
      setUploadFile(base64Url);

      const img = new Image();
      img.src = base64Url;
      img.onload = () => {
        // 获取图像的宽度和高度
        setImageWidth(img.width);
        setImageHeight(img.height);
      };

    }  
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <div className='title'>
          CLEANUP
        </div>
        <div className='info'>
          Remove object, defect, people, or text from your pictures in seconds
        </div>
      </header>
      <div>
        {!uploadFile ? (
        <div className="uploadbox">
          <div className="text">上传文件</div>
          <input type="file" className="uploadinput" accept="image/*" onChange={upLoad} />
        </div>
         ) : (
        <div className="cleanbox" style={{ width: imageWidth, height: imageHeight }} >
          <ScratchCard {...settings} width={imageWidth} height={imageHeight} uploadFile={uploadFile} onDataFromChild={handleDataFromChild}  />
          <div className="imgbox">
            <img src={uploadFile} />
          </div>
          <a className='downloadImg' target='blank' download='my_image.jpg' href={dataFromChild} >下载图片</a>
        </div>
        )}
      </div>
    </div>
  );
}

export default App;
