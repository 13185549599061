import React, { Component } from 'react';

class ScratchCard extends Component {

  constructor(props) {
    super(props);
    this.state = { loaded: false, diameter: 50 }
  }

  componentDidMount() {
    this.isDrawing = false;
    this.lastPoint = null;
    this.ctx = this.canvas.getContext('2d');
    this.ctx.fillStyle = "#000000";
    this.ctx.fillRect(0, 0, 1200, 562);
  }

  getFilledInPixels(stride) {
    if (!stride || stride < 1) {
      stride = 1;
    }

    const pixels = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
    const total = pixels.data.length / stride;
    let count = 0;

    for (let i = 0; i < pixels.data.length; i += stride) {
      if (parseInt(pixels.data[i], 10) === 0) {
        count++;
      }
    }

    return Math.round((count / total) * 100);
  }

  getMouse(e, canvas) {
    const {top, left} = canvas.getBoundingClientRect();
    const scrollTop  = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    return {
        x: (e.pageX || e.touches[0].clientX) - left - scrollLeft,
        y: (e.pageY || e.touches[0].clientY) - top - scrollTop
    }
  }

  distanceBetween(point1, point2) {
    return Math.sqrt(
      Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
    );
  }

  angleBetween(point1, point2) {
    return Math.atan2(point2.x - point1.x, point2.y - point1.y);
  }

  handleMouseDown(e) {
    this.isDrawing = true;
    this.lastPoint = this.getMouse(e, this.canvas);
  }

  handleMouseMove(e) {
    if (!this.isDrawing) {
      return;
    }

    const radius = this.state.diameter / 2;

    // e.preventDefault();

    // const currentPoint = this.getMouse(e, this.canvas);
    // const distance = this.distanceBetween(this.lastPoint, currentPoint);
    // const angle = this.angleBetween(this.lastPoint, currentPoint);

    // let x, y;

    // for (let i = 0; i < distance; i++) {
    //   x = this.lastPoint.x + (Math.sin(angle) * i);
    //   y = this.lastPoint.y + (Math.cos(angle) * i);
    //   this.ctx.globalCompositeOperation = 'destination-out';
    //   this.ctx.fillStyle = "#0000ff";
    //   this.ctx.beginPath();
    //   this.ctx.arc(x, y, 25, 0, 2 * Math.PI, false);
    //   console.log(123)
    //   this.ctx.stroke();
    // }
    // this.ctx.fillStyle = "red";
    // this.ctx.fillRect(10, 10, 100, 100);
    this.ctx.fillStyle = "#ffffff";
    this.ctx.beginPath();
    this.ctx.arc(e.nativeEvent.offsetX, e.nativeEvent.offsetY, radius, 0, Math.PI * 2);
    this.ctx.fill();

    // this.lastPoint = currentPoint;
    // this.handlePercentage(this.getFilledInPixels(32));

  }

  handleMouseUp() {
    this.isDrawing = false;
  }

  handleClick() {

    const base64url = this.canvas.toDataURL('image/png');
    const boblurl = this.props.uploadFile;

    const form = new FormData()
    form.append('image_file', boblurl)
    form.append('mask_file', base64url)

    fetch('/api/cleanup', {
        method: 'POST',
        body: form,
    })
    .then(response => response.json())
    .then(data => {
        this.props.onDataFromChild(data.imgpath);
        alert('请求成功');
    })

  }

  handleDiameterChange = (event) => {
    this.setState({ diameter: event.target.value });
  }

  render() {

    const containerStyle = {
      width: this.props.width + 'px',
      height: this.props.height + 'px',
      position: 'relative',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none'
    }

    const canvasStyle = {
      position: 'absolute',
    //   backgroundColor: '#000',
      top: 0,
      left: 0,
      zIndex: 1,
      opacity: 0.9
    }

    const resultStyle = {
      visibility: this.state.loaded ? 'visible' : 'hidden'
    }
    

    const canvasProps = {
      ref: (ref) => this.canvas = ref,
      className: 'ScratchCard__Canvas',
      style: canvasStyle,
      width: this.props.width,
      height: this.props.height,
      onMouseDown: this.handleMouseDown.bind(this),
      onTouchStart: this.handleMouseDown.bind(this),
      onMouseMove: this.handleMouseMove.bind(this),
      onTouchMove: this.handleMouseMove.bind(this),
      onMouseUp: this.handleMouseUp.bind(this),
      onTouchEnd: this.handleMouseUp.bind(this)
    }

    return (
      <div className="ScratchCard__Container" style={containerStyle}>
        <canvas {...canvasProps} ></canvas>
        <div className="ScratchCard__Result" style={resultStyle}>
          {this.props.children}
        </div>
        <div style={{position:"absolute",top:0,right:-100,fontSize:20}}>调整笔刷</div>
        <input
          style={{position:"absolute",top:30,right:-150}}
          type="range"
          min="10"
          max="200"
          value={this.state.diameter}
          onChange={this.handleDiameterChange}
        />
        <div className='btn' onClick={() => this.handleClick()}>提交</div>
      </div>
    );
  }

}


export default ScratchCard;